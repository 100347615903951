@import 'src/uikit/styles/magazine-index';

.base {
  border-radius: 10px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 1.3125rem 1rem 0;
  width: 100%;

  @include media-from(xs) {
    padding: 2.3125rem 2.75rem 0;
  }

  @include media-from(md) {
    flex-direction: row;
    padding: 4.3125rem 4.75rem 4.3125rem 0;
    width: 924px;
  }

  @include setMagazineCategoryColorFor('background');
}

.headline {
  color: $color-white--100;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.25;

  @include media-from(md) {
    font-size: 3rem;
  }
}

.text {
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  @include media-from(md) {
    font-size: 1.25rem;
    margin-bottom: 3rem;

    margin-top: 1.75rem;
  }
}

.content {
  padding-top: 0.5rem;
  text-align: center;

  @include media-from(md) {
    padding-bottom: 0.5rem;
    padding-left: 3.25rem;
    text-align: left;
  }
}

.image {
  margin: 2rem 0 2rem -1rem;
  max-width: calc(100% + 2rem);
  object-fit: contain;
  width: calc(100% + 2rem);

  @include media-to(xs) {
    height: 300px;
  }

  @include media-from(xs) {
    margin: 2rem auto;
    max-width: 20.25rem;

    width: 100%;
  }

  @include media-from(md) {
    margin: 0;
  }
}

.button {
  margin: 0 auto;

  @include setMagazineCategoryColorFor('color');

  @include media-from(md) {
    margin: 0 auto 0 0;
  }
}

// TODO typo
@import '../../../styles/magazine-index';

.wrapper1 {
  @include media-from(sm) {
    margin-left: calc(1rem + env(safe-area-inset-left));
    margin-right: calc(1rem + env(safe-area-inset-right));
  }
}

.wrapper2 {
  max-width: 1280px;
  margin: 0 auto;
}

.base {
  margin: 0;
  width: $magazine-stage-box-article-width;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 6rem;

  @include media-from(sm) {
    margin-bottom: 8.5rem;
    margin-top: -25vh;
    width: $magazine-stage-box-article-width-sm;
  }

  @include media-from(md) {
    margin-top: -28vh;
  }

  @include media-from(lg) {
    width: $magazine-stage-box-article-width;
  }
}

.contentBox {
  color: $color-white--100;
  padding: $magazine-vertical-space-sm $magazine-vertical-space-sm
    $magazine-bottom-space-sm;
  position: relative;
  z-index: 1;

  @include setMagazineCategoryColorFor('background-color');

  @include media-from(sm) {
    padding: $magazine-vertical-space $magazine-vertical-space 0;
  }
}

.skew {
  position: absolute;
  width: 100%;
  height: 100px;
  left: 0;
  bottom: -50px;
  z-index: 0;

  transform: skewY(-5deg);

  @include setMagazineCategoryColorFor('background-color');
}

@import '../../../styles/index';

.base {
  margin: 0;
  display: flex;
  flex-grow: 0;
  margin-bottom: 5rem;

  @include media-from(sm) {
    margin-bottom: 8rem;
  }

  & > * {
    display: block;
    width: 100%;
    &:nth-child(2) {
      display: none !important;
    }
    @include media-from(xs) {
      flex-basis: calc((100% - 1.5rem) / 2);
      justify-content: space-between;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      &:nth-child(2) {
        display: block !important;
      }
    }
    @include media-from(md) {
      flex-basis: calc((100% - 3rem) / 2);
      margin-bottom: 1.5rem;
    }
  }
}

.headline {
  color: $color-black--100;
  font-size: $font-size-24;
  line-height: 1.24;
  font-weight: 500;
  margin: 2rem 0;

  @include media-from(sm) {
    font-size: $font-size-30;
    margin: 3rem 0;
  }
}

@import '../../styles/index';

.item {
  list-style-type: none;
  padding: 0.75rem 1rem;
  margin: 0 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $color-surrogate--20;

    @include media-from(lg) {
      border: none;
    }
  }

  &:last-child {
    margin-bottom: 1rem;

    @include media-from(lg) {
      margin-bottom: 0;
    }
  }

  @include media-from(md) {
    margin: 0;
  }

  a {
    color: $color-black--100;
    display: flex;
    align-items: center;

    // workaround for old enercity.de styles - delete when fully relaunched
    &:hover {
      background-color: transparent;
    }
  }

  @include media-from(lg) {
    padding: 0.5rem 1rem;
  }
}

.main-action {
  margin: 0;
  padding-right: 0;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0.25rem;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  @include media-to(md) {
    border-bottom: none;
  }

  .main-action-text {
    display: none;

    @include media-from(md) {
      display: block;
      margin-left: 0.375rem;
    }
  }
}

.current {
  &:hover {
    cursor: default;
  }

  & a {
    color: $color-black--80;

    &:hover {
      cursor: default;
    }
  }
}

.as-button {
  background-color: $color-surrogate--100;
  border-radius: 25px;

  a {
    color: $color-white--100;
  }
}

.inverted a {
  color: $color-white--100;
}

@import '../../../styles/index';

.base {
  align-items: center;
  border-radius: 4px;
  color: currentColor;
  display: flex;
  gap: 1rem;
  padding: 1rem;

  &:focus-visible,
  &:hover {
    background-color: $color-surrogate--05;
    color: $color-surrogate--100;
    outline: none;
  }

  &.is-active {
    color: $color-surrogate--100;
  }

  @include media-to(lg) {
    padding: 1.25rem 0;

    &:hover {
      background-color: transparent;
      color: currentColor;
    }
  }
}

.icon {
  align-items: center;
  color: $color-surrogate--100;
  display: flex;
  flex-shrink: 0;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
}

.animated-icon {
  display: block;
  flex-shrink: 0;
  height: 3.5rem;
  width: 3.5rem;
}

.subtitle {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
}

.title {
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
}

@import '../../styles/index';

.base {
  border: 1px solid $color-surrogate--40;
  border-radius: $border-radius-default;
  background-color: $color-surrogate--10;
  color: $color-surrogate--100;
  font-size: $font-size-16;
  line-height: 1.5;
  text-align: center;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-from(sm) {
    padding: 1.25rem 2rem;
    font-size: $font-size-18;
  }

  .title {
    font-weight: $font-weight-medium;
  }

  .icon {
    margin-bottom: 0.5rem;

    @include media-from(sm) {
      width: 3rem;
      height: 3rem;
    }
  }
}

.warning {
  border-radius: $border-radius-huge;

  @include media-from(sm) {
    padding: 1.5rem;
  }
}

.link {
  font-weight: $font-weight-medium;
  color: $color-surrogate--100;
  cursor: pointer;
  margin-top: 0.25rem;
  white-space: nowrap;
  display: block;

  @include media-from(sm) {
    display: inline;
    margin-top: 0;
    margin-left: 0.5rem;
  }
}

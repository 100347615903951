@import '../../styles/index';

.is-hidden {
  .link {
    height: 0;
    opacity: 0;
    padding: 0;
    transition:
      all 0.4s ease 0.2s,
      opacity 0.2s ease;
  }

  .notice {
    line-height: 0;
    opacity: 0;
    transition:
      all 0.4s ease 0.2s,
      opacity 0.2s ease;
  }
}

.bar {
  display: block;
  position: relative;
  width: 100vw;
  z-index: $index-notice-bar;
}

.container {
  display: flex;
  margin: 0 auto;
  max-width: $layout-max-width;
  width: 100%;

  @include media-from(sm) {
    flex-direction: row;
    text-align: center;

    & > div {
      white-space: nowrap;
      width: unset;
    }
  }
}

.link {
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 1;
  padding: 1rem 0.75rem;
  transition:
    all 0.4s ease,
    opacity 0.2s ease 0.4s;
  width: 100%;
}

.notice {
  font-size: $font-size-14;
  line-height: 1.3;
  opacity: 1;
  transition:
    all 0.4s ease,
    opacity 0.2s ease 0.4s;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}

.default {
  background-color: $color-surrogate--10;

  .link,
  .notice {
    color: $color-surrogate--100 !important;
  }
}

.black-deal-action {
  background-color: $color-black--100;

  .link,
  .notice {
    color: $color-white--100 !important;
  }
}

@import 'colors';

$gradient-enercity: linear-gradient(
  90deg,
  #{$color-violet--100} 0%,
  #{$color-red--100} 100%
);

$gradient-enercity-netz: linear-gradient(
  120deg,
  #{$color-orange--100} 20%,
  #{$color-red--100} 40%,
  #{$color-violet--100} 80%
);

$gradient-enercity-netz-headline: linear-gradient(
  320deg,
  #{$color-orange--100} 0%,
  #{$color-red--100} 20%,
  #{$color-violet--100} 100%
);

$gradient-enercity-netz-nav-link: linear-gradient(
  270deg,
  #{$color-orange--100} 10%,
  #{$color-red--100} 50%,
  #{$color-violet--100} 100%
);

$gradient-enercity-netz-secondary-button: linear-gradient(
  134.05deg,
  #{$color-orange--100} 0%,
  #{$color-red--100} 60%,
  #{$color-violet--100} 100%
);

$gradient-hightlight: linear-gradient(
  222.95deg,
  $color-black--20 0%,
  $color-white--100 100%
);

@import '../../styles/magazine-index';

.button-wrapper {
  margin-bottom: -4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-from(xs) {
    margin-bottom: -5.5rem;
  }

  @include media-from(sm) {
    margin-bottom: -8.5rem;
  }
}

.button {
  z-index: 2;
  background-color: $color-white--100;
}

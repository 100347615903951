@import '../../styles/index';

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.base {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;
  width: 100%;
  height: 100%;

  color: $color-surrogate--60;

  border: 2px solid $color-surrogate--20;
  border-radius: $border-radius-default;

  cursor: pointer;

  transition: border-color $duration-short, background-color $duration-short,
    color $duration-short;

  @include media-from(sm) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.125rem;
  }
}

.label {
  padding-left: 0.75rem;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.5;
  user-select: none;

  @include media-from(sm) {
    padding-left: 0;
  }
}

.input {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  left: 0;
}

.input:checked {
  & + .base {
    border-color: $color-surrogate--100;

    background-color: $color-surrogate--05;

    &,
    & + .label {
      color: $color-surrogate--100;
    }
  }
}

.input:hover {
  & + .base {
    border-color: $color-surrogate--100;

    &,
    & + .label {
      color: $color-surrogate--100;
    }
  }
}

.input:focus {
  & + .base {
    border-color: $color-surrogate--100;
    box-shadow: $shadow-button-focus;

    &,
    & + .label {
      color: $color-surrogate--100;
    }
  }
}

.icon {
  @include media-from(sm) {
    display: none;
  }
}

.animated-icon {
  height: 2.5rem;
  display: none;

  @include media-from(sm) {
    display: block;
  }
}

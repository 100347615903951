@import '../variables/sizes';
@import '../variables/durations';
@import '../variables/shadows';
@import '../functions/media-queries';

%box-shadow-hoverable {
  position: relative;
  transition: transform $duration-default ease-in-out;

  &::before,
  &::after {
    content: '';
    display: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition:
      opacity $duration-default ease-in-out,
      box-shadow $duration-default;
    border-radius: inherit;
  }

  &::before {
    opacity: 1;
    box-shadow: $shadow-hoverable;
  }

  &::after {
    opacity: 0;
    box-shadow: $shadow-hoverable-hovered;
  }

  &:hover {
    transform: translateY(-2px);

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }

  &:focus {
    &::after {
      box-shadow: $shadow-hoverable-focused;
    }
  }
}

%text-linear-gradient {
  background-color: var(--fallback-gradient-color, #{$color-violet--100});
  background-image: var(--linear-gradient-headline, #{$gradient-enercity});

  background-clip: text;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

%list-checkmark {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;

  li {
    padding-left: 1.75rem;
    margin-bottom: 1rem;

    @include media-from(sm) {
      padding-left: 2rem;
      margin-bottom: 1rem;
    }

    p {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    &::before {
      background-color: $color-turquoise--100;
      mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.886 26.932a1.5 1.5 0 01-1.061-.44L4.44 19.108a1.5 1.5 0 112.12-2.121l6.21 6.21L26.312 6.554a1.5 1.5 0 112.328 1.893l-14.59 17.93a1.498 1.498 0 01-1.163.555' fill='%23000000' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
      content: '';
      display: block;
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: -2rem;

      @include media-from(sm) {
        transform: translateY(0.25rem);
      }
    }
  }
}

%empty-pseudo-block {
  content: '';
  display: table;
  position: relative;
  width: 100%;
}

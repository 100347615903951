@import '../../styles/index';

.base {
  margin: 0;
  position: relative;
  box-sizing: border-box;
  padding: 0.9375rem 0.9375rem 0.9375rem 0;
  max-width: 25rem;
  margin-bottom: 1.5rem;
  user-select: none;

  min-height: 76px;
  border-radius: 6px;
  background-color: $color-white--100;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 24px 0 rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: row;
  align-items: center;

  @include media-to('sm') {
    display: none;
  }
}

.bar {
  background-color: $color-surrogate--100;
  height: 100%;
}

.bar-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  width: 100%;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.title {
  color: $color-black--100;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3;

  strong {
    font-weight: $font-weight-medium;
  }
}
.icon-box-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  min-width: 3.125rem;
  cursor: pointer;
  color: $color-surrogate--100;

  border-radius: 6px;
  background-color: $color-white--100;
  transition: background-color $duration-default, color $duration-default;

  &:hover {
    background-color: rgba($color-surrogate--100, 0.05);
  }
}

.text {
  margin-right: auto;
}

.icon-box-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1.25rem;
}

.success {
  color: $color-green--100;

  .bar {
    background-color: $color-green--100;
  }
}

.error {
  color: $color-red--100;

  .bar {
    background-color: $color-red--100;
  }
}

.warning {
  color: $color-orange--100;

  .bar {
    background-color: $color-orange--100;
  }
}

.info {
  color: $color-surrogate--100;

  .bar {
    background-color: $color-surrogate--100;
  }
}

@import '../../../styles/magazine-index';

.base {
  margin-left: auto;
  margin-right: auto;
  max-width: 2560px;
}

.title {
  font-size: $font-size-30;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  margin-block-start: 0;

  @include media-from(md) {
    font-size: $font-size-48;
    margin-block-end: 1.6rem;
  }
}

.button {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  font-weight: $font-weight-bold;

  @include setMagazineCategoryColorFor('color');

  div > span {
    @include setMagazineCategoryColorFor('background-color');
  }

  @include media-from(md) {
    font-weight: $font-weight-medium;
  }
}

.slide-nav {
  align-items: center;
  color: $color-white--100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0 1.5rem 0;
  width: 10rem;
  @include media-from(md) {
    margin: 0 0 2rem 0;
  }
}

.slide-nav-button {
  cursor: pointer;
  display: inline-flex;
}

.slide-nav-bullet {
  font-size: 1rem;
  font-weight: $font-weight-regular;
  height: 1.75rem;
  justify-content: center;
  line-height: 1.75;
  width: 1.75rem;

  &.active {
    background-color: $color-white--100;
    border-radius: 50%;
    font-weight: $font-weight-bold;
    @include setMagazineCategoryColorFor('color');
  }
}

.thumbnail {
  display: block;
  max-width: calc(100% / 3);
  padding: 0.25rem;
  position: relative;
  width: 200px;
}

.thumbnail img {
  display: block;
  width: 100%;
}

@import '../../../styles/magazine-index';

.sub-title {
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: 1.25;

  @include media-from(sm) {
    font-size: $font-size-20;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    width: 7.625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: currentColor 2px solid;

    @include media-from(sm) {
      border-bottom-width: 3px;
    }
  }
}

.title {
  font-size: $font-size-30;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  margin: 0 0 1.25rem;

  @include media-from(sm) {
    font-size: $font-size-48;
    margin-bottom: 2rem;
  }
}

.social-media-list {
  height: 30px;
  margin-top: 1.5rem;
  margin-bottom: -2rem;
  @include media-from(sm) {
    margin-top: 0;
  }
  path {
    fill: $color-white--100;
  }
}

@import '../../styles/vars';

.base {
  --video-width: min(calc(100vw - 40px), 1216px);
  --video-height: calc(var(--video-width) / (16 / 9));
  --bottom-padding: 3.75rem;
  --gradient-inset: calc((var(--video-height) / 2) + var(--bottom-padding));

  background-image: linear-gradient(
    to bottom,
    #FFF,
    #FFF calc(100% - var(--gradient-inset)),
    $color-surrogate--05 calc(100% - var(--gradient-inset)),
    $color-surrogate--05
  );
  padding-bottom: var(--bottom-padding);

  @include media-from(md) {
    --bottom-padding: 7.5rem;
  }
}

.sub-title {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 1rem;

  @include media-from(sm) {
    font-size: 1.375rem;
  }
}

.video-container {
  border-radius: 1rem;
  box-shadow: 0 1rem 3rem 0 #00000014, 0 0 0.125rem 0 #00000014;
  margin-top: 3.75rem;
  overflow: hidden;

  @include media-from(md) {
    margin-top: 5rem;
  }
}

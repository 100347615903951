@import '../../../styles/index';

.base {
  font-size: $font-size-18;
  line-height: 1.8;
  font-weight: $font-weight-regular;
  margin-bottom: 1rem;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  ul > li {
    margin-bottom: 0.25rem;

    @include media-from(sm) {
      margin-bottom: 0.5rem;
    }
  }
}

.simple {
  ul > li:before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.886 26.932a1.5 1.5 0 01-1.061-.44L4.44 19.108a1.5 1.5 0 112.12-2.121l6.21 6.21L26.312 6.554a1.5 1.5 0 112.328 1.893l-14.59 17.93a1.498 1.498 0 01-1.163.555' fill='%23009AA3' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  }
}

.highlighted {
  color: $color-white--100;
  ul > li:before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.886 26.932a1.5 1.5 0 01-1.061-.44L4.44 19.108a1.5 1.5 0 112.12-2.121l6.21 6.21L26.312 6.554a1.5 1.5 0 112.328 1.893l-14.59 17.93a1.498 1.498 0 01-1.163.555' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  }
}

@import '../../../styles/index';

.base {
  line-height: 1.25;
  margin: 0;
  margin-bottom: 1rem;

  @include media-from(sm) {
    margin-bottom: 1.5rem;
  }

  &.default {
    font-size: $font-size-28;
  }

  @include media-from(sm) {
    font-size: $font-size-32;
  }

  &.large {
    font-size: $font-size-30;

    @include media-from(sm) {
      font-size: $font-size-44;
    }
  }
}

.simple {
  color: $color-black--100;
}

.highlighted {
  color: $color-white--100;
}

@import '../../styles/index';

.base {
  background: $color-violet--100 $gradient-enercity;
  background-clip: text;
  box-decoration-break: clone;
  color: transparent;
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  line-height: 1.15;

  @include media-from(lg) {
    font-size: $font-size-28;
  }
}

.subline-container {
  align-items: center;
  display: flex;

  @include media-from(lg) {
    margin-top: 0.125rem;
  }
}

.subline {
  background: $color-violet--100 $gradient-enercity;
  background-clip: text;
  color: transparent;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: 1;

  @include media-from(lg) {
    font-size: $font-size-18;
  }
}

.icon {
  color: $color-red--100;
  margin-left: 0.125rem;
}

@import '../../../styles/magazine-index';

.base {
  flex: 1;
  position: relative;
  max-width: 100%;
  @include setMagazineCategoryColorFor('color');
}

a.base {
  cursor: pointer;
  @include setMagazineCategoryColorFor('color');

  .highlighted {
    color: $color-white--100;
  }
}

.base-wrapper {
  position: relative;
  width: 100%;
  box-shadow: $shadow-card-soft;
  border-radius: $border-radius-large;

  @include media-from(sm) {
    height: 100%;
  }
}

.wrapper {
  overflow: hidden;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  height: 100%;
}

.content-wrapper {
  position: relative;
  z-index: $index-amazing-number;
  padding: 2.5rem 1rem;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  @include media-from(sm) {
    padding: 3rem 2rem;
  }
}

.content-with-image {
  &.content-wrapper {
    justify-content: flex-start;
  }

  @include media-from(sm) {
    padding: 3rem 2rem 1rem;
  }
}

.number {
  font-size: 4rem;
  font-weight: $font-weight-bold;
  line-height: 1;

  @include media-from(sm) {
    font-size: 6rem;
  }

  &.number-large {
    font-size: 5.5rem;
  }

  &.number-medium {
    font-size: 4rem;
  }
}

.title {
  color: $color-black--100;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  margin-bottom: 1rem;
  text-align: center;

  @include media-from(sm) {
    margin-bottom: 2rem;
    font-size: $font-size-24;
  }
}

.text {
  color: $color-black--80;
  font-size: $font-size-16;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}

.content-wrapper {
  justify-content: center;
}

.simple {
  @include setMagazineCategoryColorFor('color');
}

.highlighted {
  color: $color-white--100;
  @include setMagazineCategoryColorFor('background-color');

  .number {
    color: $color-black--100;
  }

  .text {
    color: $color-white--100;
  }
}

.image-bottom {
  @include media-to(sm) {
    display: none;
  }

  img {
    top: unset;
    bottom: 0;
  }
}

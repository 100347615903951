@import '../../styles/index';

.base {
  position: relative;
}

.button {
  align-items: stretch;
  background-color: $color-white--100;
  border: none;
  border-radius: 0.25rem;
  box-shadow: inset 0rem 0rem 0rem 0.125rem $color-black--20;
  color: $color-black--40;
  cursor: pointer;
  display: flex;
  font-size: $font-size-18;
  line-height: 1.5rem;
  min-height: 3.75rem;
  outline: 0.125rem solid transparent;
  outline-offset: 0.125rem;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;

  .icon {
    align-items: center;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: inset 0rem 0rem 0rem 0.125rem $color-black--20;
    display: flex;
    height: 3.75rem;
    justify-content: center;
    padding: 1.125rem;
    // only for safaris display bug
    width: 3.75rem;
  }

  .content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0.5rem 3rem 0.5rem 1rem;
  }

  .label {
    color: $color-surrogate--60;
    display: block;
    font-size: $font-size-18;
    position: absolute;
    top: 0;
    transform: scale(1) translate(0, 1.0625rem);
    transform-origin: left bottom;
    transition:
      transform $duration-default linear,
      color $duration-default ease-in-out;
    user-select: none;

    &.active {
      transform: scale(0.7) translate(0, 0.375rem);
    }
  }

  .text {
    line-height: 1.3125;
    margin-top: 14px;
    opacity: 0;
    position: absolute;
    top: 26px;
    transition:
      opacity $duration-default,
      visibility $duration-default,
      margin-top $duration-default;

    &.active {
      margin-top: 0;
      opacity: 1;
    }
  }

  .chevron {
    align-items: center;
    bottom: 0;
    color: $color-surrogate--100;
    display: flex;
    margin-right: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 250ms linear;

    &.active {
      transform: rotate(180deg);
    }
  }
}

.options-wrapper {
  background-color: $color-white--100;
  border-radius: 0.1875rem;
  box-shadow:
    0rem 0rem 0.125rem rgba(0, 0, 0, 0.1),
    0rem 0rem 1.6875rem rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0 0 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: $index-listbox-option;

  &.relative {
    position: relative;
  }
}

.options-wrapper-open {
  pointer-events: all;
}

.options {
  list-style-image: initial;
  list-style-position: initial;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.option {
  border-radius: 0.1875rem;
  font-size: $font-size-18;
  padding: 0.5rem 1rem;
  text-align: left;
}

.input-group {
  border-bottom: 0.0938rem solid #e8e9e8;
  display: flex;
  margin-bottom: 0.5rem;
  padding-block: 1rem;
}

.input {
  flex-grow: 1;
}

.submit-button {
  align-items: center;
  background-color: $color-black--20;
  border: none;
  border-radius: 0.1875rem;
  color: $color-black--40;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 3.75rem;
  justify-content: center;
  margin: 0 0 0 0.75rem;
  padding: 0;
  width: 3.75rem;

  &:focus {
    box-shadow:
      inset 0rem 0rem 0rem 0.125rem $color-surrogate--100,
      0rem 0rem 0rem 0.25rem rgba($color-surrogate--100, 0.12);
    outline: none;
  }

  &.active {
    background-color: $color-surrogate--100;
    color: $color-white--100;
  }
}

@mixin input-listbox-color-theme(
  $base-color,
  $color-10,
  $color-20,
  $color-40,
  $color-60,
  $shadow,
  $shadow-active,
  $shadow-highlight
) {
  &.button {
    box-shadow: $shadow;

    .icon {
      box-shadow: $shadow;
    }

    .label {
      color: $color-60;
    }

    .text {
      color: $base-color;
    }

    .chevron {
      color: $base-color;
    }

    &:focus {
      box-shadow: $shadow-active, $shadow-highlight;

      .icon {
        box-shadow: $shadow-active;
      }
    }
  }

  &.submit-button {
    background-color: $color-10;
    color: $color-60;

    &:focus {
      box-shadow: $shadow-active, $shadow-highlight;
      outline: none;
    }

    &.active {
      background-color: $base-color;
      color: $color-white--100;
    }
  }

  &.icon {
    background: rgba($base-color, 0.05);
    color: $base-color;
  }

  &.text {
    color: $base-color;
  }

  &.option {
    color: $base-color;

    &.selected:not(.disabled),
    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: rgba($base-color, 0.1);
      cursor: pointer;
      outline: none;
    }

    &.disabled {
      color: $color-40;
      cursor: not-allowed;
      user-select: none;
    }
  }
}

.surrogate {
  @include input-listbox-color-theme(
    $color-surrogate--100,
    $color-surrogate--10,
    $color-surrogate--20,
    $color-surrogate--40,
    $color-surrogate--60,
    $shadow-input-listbox-surrogate,
    $shadow-input-listbox-surrogate-active,
    $shadow-input-listbox-surrogate-highlight
  );
}

.turquoise {
  @include input-listbox-color-theme(
    $color-turquoise--100,
    $color-turquoise--10,
    $color-turquoise--20,
    $color-turquoise--40,
    $color-turquoise--60,
    $shadow-input-listbox-turquoise,
    $shadow-input-listbox-turquoise-active,
    $shadow-input-listbox-turquoise-highlight
  );
}

.orange {
  @include input-listbox-color-theme(
    $color-orange--100,
    $color-orange--10,
    $color-orange--20,
    $color-orange--40,
    $color-orange--60,
    $shadow-input-listbox-orange,
    $shadow-input-listbox-orange-active,
    $shadow-input-listbox-orange-highlight
  );
}

.surrogate,
.turquoise,
.orange {
  &.button {
    &.invalid {
      background-color: rgba($color-red--100, 0.05);
      box-shadow: $shadow-error-ring;

      &,
      .label,
      .placeholder,
      .text,
      .chevron {
        color: $color-red--100;
      }

      .icon {
        background-color: rgba($color-red--100, 0.05);
        box-shadow: inset 0rem 0rem 0rem 0.125rem $color-red--100;
        color: $color-red--100;
      }
    }
  }
}

@import '../../styles/index';

.base {
  display: flex;
  padding: 1.125rem;
  align-items: center;
  justify-content: space-between;
  color: $color-black--60;

  * > label {
    font-size: $font-size-18 !important;
  }

  @include media-to(sm) {
    flex-direction: column;
  }
}

@import '../../styles/index';

.base {
  margin: 0;
  display: flex;
  align-items: center;
}

.bottom-left {
  position: fixed;
  left: 45px;
  bottom: 10px;
  z-index: 2;
}

.bottom-right {
  position: fixed;
  right: 45px;
  bottom: 10px;
  z-index: 2;
}

.bottom-higher-left {
  position: fixed;
  left: 20px;
  bottom: 74px;
  z-index: 2;
}

@import '../index';

body.body {
  :global(.strong-surrogate) {
    color: $color-surrogate--100;
  }

  :global(.TwoColumnContainer-module-wrapper) {
    max-width: $container-size-default;
  }

  :global(.TwoColumnContainer-module-justified-left),
  :global(.TwoColumnContainer-module-justified-right) {
    @include media-from(md) {
      max-width: $container-size-default;
    }
  }

  :global(.RichText-module-base),
  :global(.GuideBox-module-base),
  :global(.Table-module-base) {
    font-size: 1.125rem !important;

    ul {
      list-style-type: disc !important;
      padding-left: 1.5rem !important;

      li {
        padding-left: 0 !important;
        margin-bottom: 0 !important;

        &::before {
          display: none !important;
        }
      }

      br {
        display: none !important;
      }
    }

    table th strong,
    table td strong {
      font-weight: 700;
    }
  }

  :global(.Table-module-base) {
    table td,
    table th {
      padding: 0.75rem;
      @include media-from(sm) {
        padding: 1rem;
      }
    }
  }

  :global(.Quote-module-base .RichText-module-base) {
    font-size: 1.75rem !important;
  }

  :global(.Table-module-base.Table-module-has-additional-headline) table thead,
  :global(.Table-module-base.Table-module-has-additional-headline)
    table
    .Table-module-additional-headline-row {
    background-color: white !important;
  }

  :global(.Table-module-base.Table-module-has-additional-headline)
    table
    tbody
    tr:not(:first-child):not(:global(.Table-module-additional-headline-row)) {
    border: none !important;
  }

  > br:last-child {
    display: none !important;
  }

  :global(.Quote-module-base) {
    max-width: 919px;
    :global(.Quote-module-avatar) {
      padding-top: 1.625rem;
      margin-right: 2.625rem;
    }

    :global(.Quote-module-quote) {
      font-size: $font-size-20;
      font-weight: $font-weight-medium;

      @include media-from(sm) {
        font-size: $font-size-24;
      }
    }
    :global(.Quote-module-cite) {
      strong,
      span {
        font-size: $font-size-18;
      }
    }
    :global(.Quote-module-quote),
    :global(.Quote-module-cite) {
      > * {
        background: $color-violet--100
          linear-gradient(
            320deg,
            #{$color-red--100} 0%,
            #{$color-violet--100} 100%
          );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
      }
    }
  }
}

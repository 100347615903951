@import '../../../styles/magazine-index';

.base {
  margin: 0;
  width: 100%;

  a {
    color: $color-black--100;
  }
}

.headline {
  text-align: center;

  @include media-from(md) {
    text-align: left;
  }
}

.title {
  font-size: $font-size-30;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  margin: 0 0 1rem 0;
  text-align: inherit;

  @include media-from(md) {
    margin-bottom: 0.5rem;
  }
}

.sub-title {
  font-size: $font-size-18;
  line-height: 1.4;
  display: block;
  margin-top: 0;
  margin-bottom: 2.5rem;
  color: $color-black--100;
  font-weight: $font-weight-light;

  @include media-from(md) {
    font-size: $font-size-20;
    max-width: calc(100vw / 3 * 2);
  }
}

.list {
  display: grid;
  row-gap: 2rem;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

  @include media-from(xs) {
    grid-template-rows: none;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 2rem;
  }

  @include media-from(sm) {
    overflow: initial;
  }

  @include media-from(md) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 4rem;
    overflow: initial;
  }
}

.item-link {
  display: block;
}

.hideTablet {
  @include media-from(xs) {
    display: none;
  }

  @include media-from(md) {
    display: block;
  }
}

@import '../../styles/index';

.multi-select {
  display: flex;
  position: relative;
}

.button {
  all: unset;
  align-items: center;
  background-color: $color-white--100;
  border: 2px solid $color-surrogate--20;
  border-radius: $border-radius-small;
  contain: strict;
  display: flex;
  flex-grow: 1;
  line-height: 1.6;
  padding: 0.5rem 1rem;
  user-select: none;
  width: min-content;

  &.active,
  &:focus {
    box-shadow: $shadow-button-focus;
  }

  &.active {
    border-color: $color-surrogate--100;

    &.with-gradient {
      .label {
        color: $color-white--100;
      }
    }

    .label {
      color: $color-surrogate--100;
    }
  }
}

.button:focus,
.button:hover {
  cursor: pointer;
}

.button.with-gradient {
  background-color: rgba(255, 255, 255, 0.4);
  border: 2px solid transparent;
}

.button:focus,
.button[aria-expanded='true'] {
  &.with-gradient {
    border-color: $color-white--100;
  }
}

.label {
  color: $color-surrogate--60;

  display: flex;
  flex: 1;
  font-size: $font-size-18;
  justify-content: space-between;
  text-overflow: ellipsis;

  &.with-gradient {
    color: $color-white--100;
  }
}

.icon {
  color: $color-surrogate--100;
  display: flex;
  transition: transform $duration-default;

  &.rotated {
    transform: rotate(180deg);
  }

  &.with-gradient {
    color: $color-white--100;
  }
}

.selectionList {
  background-color: $color-white--100;
  border-radius: $border-radius-small;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  display: none;
  margin: 0;
  max-height: 22.5rem;
  overflow-y: auto;
  padding: 0.625rem;
  padding-right: 0;
  position: absolute;
  top: calc(100% + 0.75rem);
  width: 100%;
  z-index: $index-form-field-dropdown-list;

  &::-webkit-scrollbar {
    width: 28px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-surrogate--20;
    background-clip: padding-box;
    border: 10px solid transparent;
    border-radius: 13px;
  }

  &:focus {
    outline: none;
  }
}

.button[aria-expanded='true'] + .selectionList {
  display: block;
}

.placeholder-number {
  align-items: center;
  background-color: $color-surrogate--100;
  border-radius: 4px;
  color: $color-white--100;
  display: none;
  font-size: $font-size-18;
  height: 1.625rem;
  justify-content: center;
  line-height: 1.1;
  margin-right: 0.75rem;
  width: 1.625rem;

  &.is-active {
    display: flex;
  }

  &.with-gradient {
    background-color: $color-white--100;
    color: $color-surrogate--100;
  }
}
